import React from "react";
import "./footer.css";

function Footer() {
  return (
    <footer>
      <hr />
      <div className="socials-section">
        <div className="left-section">
          <div className="footer-section-title">
            Study Melbourne newsletters
          </div>
          <p>
            The latest news, events and advice delivered straight to your inbox
            every month.
          </p>
          <div className="social-button-belt">
            <button
              onClick={() => {
                const url =
                  "https://www.studymelbourne.vic.gov.au/student-update";
                window.open(url, "_blank");
              }}
            >
              For international students
            </button>

            <button
              onClick={() => {
                const url =
                  "https://www.studymelbourne.vic.gov.au/international-education-insider";
                window.open(url, "_blank");
              }}
            >
              For industry stakeholders
            </button>
          </div>
        </div>

        <div className="right-section">
          <div className="footer-section-title">Social media</div>
          <ul className="socials-ul ul-dotless">
            <div className="ul-left ul-dotless">
              <li>
                <i class="fab fa-facebook-f"></i>
                &nbsp;&nbsp;
                <a href="https://www.facebook.com/StudyMelbourneVIC">
                  Facebook
                </a>
              </li>
              <li>
                <i class="fab fa-linkedin-in"></i>
                &nbsp;&nbsp;
                <a href="https://www.linkedin.com/company/6467100">LinkedIn</a>
              </li>
              <li>
                <i class="fab fa-youtube"></i>
                &nbsp;&nbsp;
                <a href="https://www.youtube.com/user/studymelbourne">
                  YouTube
                </a>
              </li>
            </div>
            <div className="ul-right ul-dotless">
              <li>
                <i class="fab fa-weixin"></i>
                &nbsp;&nbsp;
                <a href="https://www.studymelbourne.vic.gov.au/contact-study-melbourne#wechat">
                  WeChat 微信
                </a>
              </li>
              <li>
                <i class="fab fa-instagram"></i>
                &nbsp;&nbsp;
                <a href="https://www.instagram.com/StudyMelbourne/">
                  Instagram
                </a>
              </li>
            </div>
          </ul>
        </div>
      </div>

      <hr />

      <div className="contact-section">
        <div className="contact-links">
          <ul className="ul-dotless">
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/contact-study-melbourne">
                Contact us
              </a>
            </li>
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/about-study-melbourne/copyright">
                Copyright
              </a>
            </li>
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/about-study-melbourne/disclaimer">
                Disclaimer
              </a>
            </li>
          </ul>
          <ul className="ul-dotless">
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/about-study-melbourne/privacy">
                Privacy
              </a>
            </li>
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/about-study-melbourne/accessibilty">
                Accessibility
              </a>
            </li>
            <li>
              <a href="https://www.studymelbourne.vic.gov.au/sitemap">
                Sitemap
              </a>
            </li>
          </ul>
        </div>
        <div className="ack-excerpt">
          <div>
            <div className="ack">
              We acknowledge the Traditional Aboriginal Owners of Country
              throughout Victoria and pay our respects to them, their
              connections to land, sea, and community. We pay our respects to
              their Elders past and present and future Traditional Owners.
            </div>
            <div className="flag-wrapper">
              <img
                className="flag"
                src="https://www.studymelbourne.vic.gov.au/?a=61931"
                alt=""
              />
              <img
                className="flag"
                src="https://www.studymelbourne.vic.gov.au/?a=61932"
                alt=""
              />
            </div>
          </div>
          <div>
            <div className="ack">
              We respect and welcome people of all backgrounds, genders,
              sexualities, abilities and cultures.
            </div>
            <div className="flag-wrapper">
              <img
                className="flag"
                src="https://www.studymelbourne.vic.gov.au/?a=189121"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="copyright-section">
        <ul className="copyright-text ul-dotless">
          <li>State Government of Victoria</li>
          <li>© Copyright Study Melbourne 2023</li>
          <li>Page updated: 26 Oct 2023</li>
        </ul>
        <img
          src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c9/Victoria_State_Government_logo.svg/832px-Victoria_State_Government_logo.svg.png?20210511003508"
          alt=""
          onClick={() => {
            window.open("https://www.vic.gov.au/", "_blank");
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    </footer>
  );
}

export default Footer;
