import { useState } from "react";

import "./dropdown.css";

const Dropdown = (props) => {
  const [dropdownState, setDropdownState] = useState(false);

  function dropdownToggle() {
    setDropdownState(!dropdownState);
  }

  return (
    <>
      <div className={`dropdown-body ${dropdownState && "open"}`}>
        <h5>Features</h5>
        <ul>
          {props.item.features.map((currentFeature, featureIndex) => {
            return <li key={featureIndex}>{currentFeature}</li>;
          })}
        </ul>

        <h5>Potential Issues</h5>
        <ul>
          {props.item.issues.map((currentIssue, issueIndex) => {
            return <li key={issueIndex}>{currentIssue}</li>;
          })}
        </ul>

        <h5>Costs</h5>
        <p>{props.item.costs}</p>

        {/* {props.item.links ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "2em",
            }}
          >
            <h5>Related Articles</h5>
            <a
              href={props.item.links.link1.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--accent-color)" }}
            >
              {props.item.links.link1.text}
            </a>
            <a
              href={props.item.links.link2.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: "var(--accent-color)" }}
            >
              {props.item.links.link2.text}
            </a>
          </div>
        ) : (
          <p>none</p>
        )} */}
      </div>
      <div
        className="dropdown-btn tabable"
        onClick={dropdownToggle}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            dropdownToggle();
          }
        }}
        tabIndex="0"
      >
        {dropdownState ? "Show less" : "Read more"}
        <span>
          <i
            className={`fa fa-chevron-down icon ${dropdownState && "open"}`}
          ></i>
        </span>
      </div>
    </>
  );
};

export default Dropdown;
