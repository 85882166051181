import React from "react";
import "./landingPage.css";
import Header from "../header/header.jsx";
import Footer from "../footer/footer";

function LandingPage(props) {
  return (
    <>
      <Header />
      <div className="landingPage-grid">
        <img src="images/Accommodation Banner.png" alt="" />
        <div className="text-splash">
          Designed specifically for international students coming to Melbourne,
          use this tool to discover what kind of accommodation matches your
          preferences.
          <div className="splash-heading">
            To see your recommended accommodation type:
          </div>
          <p>
            1. Walk through the questions by selecting your answer, you will be
            automatically taken to the next question.
          </p>
          <div className="splash-btn-belt">
            <div className="pseudo-btn-hollow">Yes</div>
            <div className="pseudo-btn-hollow">No</div>
          </div>
          <p>
            2. If you need to change the answer, just click on the arrow to
            return to the previous question.
          </p>
          <i
            className="fas fa-arrow-left back-button"
            style={{ cursor: "alias", marginTop: 0 }}
          ></i>
          <p>3. View your results.</p>
          <p>
            4. Click on 'How we worked this out' to find out how you obtained
            your results.
          </p>
          <div className="splash-btn-belt">
            <div className="pseudo-btn-hollow" disabled={true} tabIndex={-1}>
              How we worked this out
            </div>
          </div>
          <div className="start-btn-wrapper">
            <button
              onClick={() => {
                props.setPage(0);
              }}
              style={{ marginTop: "2rem" }}
            >
              Start quiz <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
