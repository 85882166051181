import React, { useState } from "react";
import "./header.css";

function Header() {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header>
      {/* nav */}
      <nav className={navOpen ? "topnav responsive" : "topnav"} id="navTop">
        <div className="logoWrap">
          <img
            className="logo"
            onClick={() =>
              window.open("https://www.studymelbourne.vic.gov.au/", "_blank")
            }
            src="images/logo_with_vic.png"
            alt="study Melbourne logo"
          />
        </div>
        <div className="nav-wrap">
          <div className="menu-title">
            <p>Menu</p>
          </div>
          <div className="firstRow">
            <a
              href="https://www.studymelbourne.vic.gov.au/industry"
              className="xactive"
              target="_blank"
              rel="noreferrer"
            >
              Industry
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/about-study-melbourne"
              target="_blank"
              rel="noreferrer"
            >
              About us
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/contact-study-melbourne"
              target="_blank"
              rel="noreferrer"
            >
              Contact
            </a>
          </div>
          <div className="nav-primary">
            <a
              href="https://www.studymelbourne.vic.gov.au/choose-melbourne"
              target="_blank"
              rel="noreferrer"
            >
              Future students
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/experience-melbourne-victoria"
              target="_blank"
              rel="noreferrer"
            >
              Current students
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/case-studies-and-student-stories"
              target="_blank"
              rel="noreferrer"
            >
              Stories
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/news-updates"
              target="_blank"
              rel="noreferrer"
            >
              Updates
            </a>
            <a
              href="https://www.studymelbourne.vic.gov.au/search?collection=study-melbourne-events"
              target="_blank"
              rel="noreferrer"
            >
              Events
            </a>
          </div>
        </div>
        <div href="" className="icon" onClick={toggleNav}>
          <i className="fa fa-bars fa-lg"></i>
        </div>
      </nav>
      {/* nav */}

      <div className="at-banner">
        <div className="banner-text">
          <i class="fas fa-calculator"></i> Accommodation quiz
        </div>
      </div>
    </header>
  );
}

export default Header;
