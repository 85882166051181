import "./App.css";
import WeightedQuiz from "./WeightedQuiz/WeightedQuiz";
import React, { useEffect } from "react";
// import Accommodation from './WeightedQuiz/Accomodation';
// import Stats from "./WeightedQuiz/Stats";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* <Route path="/stats">
            <Stats />
          </Route> */}
          {/* <Route path="/:id">
              <Accommodation />
            </Route> */}
          <Route path="/">
            <WeightedQuiz />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
