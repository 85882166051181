import React, { useState, useEffect } from "react";
import * as EmailValidator from "email-validator";

import "./weighted_quiz.css";

import { questions, outcomeDetails } from "./questions";

import WorkingCard from "./WorkingCard";
import Dropdown from "./mini-components/Dropdown";

import Footer from "./footer/footer.jsx";
import Header from "./header/header.jsx";
import LandingPage from "./landing page/LandingPage";

//react components using the weighted quiz elements have
// params @ the callback function should know which element is it calling
// Google chorme has stuff that you can add to you objects and add more items to

const WeightedQuiz = () => {
  //preloading images
  useEffect(() => {
    const pictures = [
      // "images/illustrations/accommodation-illustrations-live-with-18-25@2x.png",
      // "images/illustrations/accommodation-illustrations-lived-out-of-home-before@2x.png",
      // "images/illustrations/accommodation-illustrations-living-with-other-people@2x.png",
      // "images/illustrations/accommodation-illustrations-living-with-aus-family@2x.png",
      // "images/illustrations/accommodation-illustrations-provider-cook-you-food@2x.png",
      // "images/illustrations/accommodation-illustrations-share-bathroom@2x.png",
      // "images/illustrations/accommodation-illustrations-tutoring@2x.png",
      // "images/illustrations/accommodation-illustrations-social-activities@2x.png",
      // "images/illustrations/accommodation-illustrations-transport-option@2x.png",
      // "images/illustrations/accommodation-illustrations-some-of-the-cleaning@2x.png",
      // "images/illustrations/accommodation-illustrations-funished-when-you-arrive@2x.png",
      // "images/illustrations/accommodation-illustrations-kid-to-look-after@2x.png",
      // "images/illustrations/accommodation-illustrations-partner-to-live-with@2x.png",
      "images/StudyMelbImages/Study-Mel-funished-when-you-arrive.png",
      "images/StudyMelbImages/Study-Mel-homestay.png",
      "images/StudyMelbImages/Study-Mel-kid-to-look-after.png",
      "images/StudyMelbImages/Study-Mel-live-with-18-25.png",
      "images/StudyMelbImages/Study-Mel-lived-out-of-home-before.png",
      "images/StudyMelbImages/Study-Mel-living-with-aus-family.png",
      "images/StudyMelbImages/Study-Mel-living-with-other-people.png",
      "images/StudyMelbImages/Study-Mel-partner-to-live-with.png",
      "images/StudyMelbImages/Study-Mel-private-housemates.png",
      "images/StudyMelbImages/Study-Mel-private-rental-alone.png",
      "images/StudyMelbImages/Study-Mel-provider-cook-you-food.png",
      "images/StudyMelbImages/Study-Mel-purpose-built.png",
      "images/StudyMelbImages/Study-Mel-Residential-Colleges.png",
      "images/StudyMelbImages/Study-Mel-share-bathroom.png",
      "images/StudyMelbImages/Study-Mel-social-activities.png",
      "images/StudyMelbImages/Study-Mel-some-of-the-cleaning.png",
      "images/StudyMelbImages/Study-Mel-transport-option.png",
      "images/StudyMelbImages/Study-Mel-tutoring.png",
    ];
    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
    });
  });

  //save use details in this state
  const [userDetails, setUserDetails] = useState({
    nationality: "Australia",
  });

  function userFromChangeHandler(e) {
    let name = e.target.name;
    let value = e.target.value;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  }

  //block for handling different pages
  var maxPage = questions.length - 1;
  // const [page, setPage] = useState("landing");
  const [page, setPage] = useState("landing");

  function next() {
    if (page < maxPage) setPage(page + 1);
    else if (page === maxPage) {
      //   setPage("register");
      setPage("results");
    }
  }

  function prev() {
    if (page > 0) setPage(page - 1);
    else setPage("landing");
  }

  //consent toggler
  const [consentState, setConsentState] = useState(false);

  function consentToggler() {
    setConsentState(!consentState);
  }

  //form validation
  const [formMessage, setFromMessage] = useState("");

  function formValidator() {
    if (!userDetails.firstName || !userDetails.nationality)
      setFromMessage("Please enter you first name ");
    else if (!userDetails.lastName)
      setFromMessage("Please enter you last name ");
    // else if(!userDetails.email)
    else if (!EmailValidator.validate(userDetails.email))
      // true
      setFromMessage("Please enter a valid email ");
    else if (!userDetails.nationality)
      setFromMessage("Please select your nationality ");
    else if (!consentState)
      setFromMessage("Please agree to our privacy policy");
    else {
      setPage("results");
    }
  }

  //state for saving choices
  const [currentChoice, setChoice] = useState({});

  //state for saving weights
  const [currentWeight, setWeight] = useState({});

  if (page === "landing") {
    return (
      <>
        <LandingPage page={page} setPage={setPage} />
      </>
    );
  }

  if (page >= 0 && page <= maxPage) {
    window.scrollTo(0, 0);
    return (
      <div>
        <Header />

        <div className="snapping-container">
          <div className="navbar">
            {/* <quiz-title style={{fontSize:"2em", fontFamily: "Brandon-bold"}} onClick={()=>{setPage("landing")}}>Insider Guides</quiz-title> */}
            <page-no
              style={{
                fontSize: "1.5em",
                fontFamily: "custom-bold",
                color: "var(--accent-color)",
              }}
            >
              {page + 1}/{maxPage + 1}
            </page-no>
            <i
              tabIndex="0"
              className="fas fa-redo fa-lg tabable"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setPage("landing");
                }
              }}
              onClick={() => {
                setPage("landing");
              }}
              style={{ cursor: "pointer", color: "var(--accent-color)" }}
            ></i>
          </div>
          <div
            className="progress-bar-wrapper"
            style={{ backgroundColor: "var(--main-bg-color)" }}
          >
            <div className="progress bar">
              <div
                style={{
                  height: "0.5em",
                  width: `${((page + 1) / (maxPage + 1)) * 100}%`,
                  backgroundColor: "var(--accent-color)",
                  borderRadius: "0 5px 5px 0",
                }}
              ></div>
            </div>
          </div>
          <div className="question-wrapper">
            <h4>{questions[page].question}</h4>
          </div>
        </div>

        <div
          key={Math.random()}
          className="container animated animatedFadeInUp fadeInUp"
        >
          <div className="options-img-wrapper">
            <options-section>
              <div className="option-wrapper">
                {questions[page].options.map(
                  (currentOption, currentOptionIndex) => (
                    <div
                      className="option-element tabable"
                      key={page + "-" + currentOptionIndex}
                      label={currentOption[0]}
                      tabIndex="0"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setWeight({
                            ...currentWeight,
                            [page]: currentOption[1],
                          });
                          setChoice({
                            ...currentChoice,
                            [page]: {
                              question: questions[page].question,
                              selected: currentOption[0],
                              weight: currentOption[1],
                              info: questions[page].info,
                            },
                          });
                          next();
                        }
                      }}
                      onClick={(e) => {
                        setWeight({
                          ...currentWeight,
                          [page]: currentOption[1],
                        });
                        setChoice({
                          ...currentChoice,
                          [page]: {
                            question: questions[page].question,
                            selected: currentOption[0],
                            weight: currentOption[1],
                            info: questions[page].info,
                          },
                        });
                        next();
                      }}
                    >
                      {currentOption[0]}
                    </div>
                  )
                )}
              </div>

              <i
                tabIndex="0"
                className="fas fa-arrow-left back-button tabable"
                onClick={prev}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    prev();
                  }
                }}
              ></i>
            </options-section>

            <div className="question-img">
              <img src={questions[page].img} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (page === "workings") {
    const choiceArray = Object.values(currentChoice);

    return (
      <>
        <Header />
        <div className="container">
          <h3>
            Hi {userDetails.firstName}, this is how we worked out the best
            accommodation for you..
          </h3>

          <h5
            style={{
              fontSize: "1.5em",
              margin: "0 0 1em 0",
              fontWeight: "bold",
            }}
          >
            How to read this page :
          </h5>
          <h5 style={{ fontSize: "1.25em", margin: "0 0 0.4em 0" }}>
            <i className="fas fa-check-circle fa-lg"></i> = This type of
            accommodation suits your needs based on your answer
          </h5>
          <h5 style={{ fontSize: "1.25em", margin: "0 0 0.4em 0" }}>
            <i className="fas fa-exclamation-circle fa-lg"></i> = This might or
            might not work for you, please check the description
          </h5>
          <h5 style={{ fontSize: "1.25em", margin: "0 0 0.4em 0" }}>
            <i className="fas fa-times-circle fa-lg"></i> = This type of
            accommodation does not fulfil your requirements
          </h5>

          {choiceArray.map((choice, choiceIndex) => {
            return <WorkingCard key={choiceIndex} choice={choice} />;
          })}
          <div className="button-wrapper">
            <button
              onClick={() => {
                setPage("results");
              }}
            >
              Back to results
            </button>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (page === "results") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("event", "Results Viewed", {
      event_category: "Quiz analytics",
      event_label: "Completed quiz",
    });

    //calculing the winner
    const weightArray = Object.values(currentWeight);
    console.log(weightArray);

    //initialize the array that would hold to total weights to 0
    var summedWeightArray = [];
    for (let i = 0; i < Object.keys(outcomeDetails).length; i++) {
      summedWeightArray[i] = 0;
    }

    //add all the wieghts of the of the selected options
    for (let i = 0; i < weightArray.length; i++) {
      for (let j = 0; j < Object.keys(outcomeDetails).length; j++) {
        summedWeightArray[j] = summedWeightArray[j] + weightArray[i][j];
      }
    }
    console.log("summed array is", summedWeightArray);

    //create a 2d array to hold the outcomes and the corresponding summed weights
    var finalScore2DArray = [];
    for (let i = 0; i < Object.keys(outcomeDetails).length; i++) {
      //remove the 3rd elment if it breaks======================
      finalScore2DArray[i] = [
        outcomeDetails[Object.keys(outcomeDetails)[i]].title,
        summedWeightArray[i],
        Object.keys(outcomeDetails)[i],
      ];
      // console.log(outcomeDetails[Object.keys(outcomeDetails)[i]].title);
      console.log(Object.keys(outcomeDetails)[i]);
    }
    //sort the final scorred 2d array in descending order
    finalScore2DArray.sort(function (a, b) {
      return b[1] - a[1];
    });
    console.log(finalScore2DArray);

    window.scrollTo(0, 0);

    return (
      <>
        <Header />
        <div className="container">
          {/* <div className="navbar">
                    <quiz-title style={{fontSize:"2em", fontFamily: "Brandon-bold"}} onClick={()=>{setPage("landing")}}>Insider Guides</quiz-title>
                    <i class="fas fa-redo fa-lg" onClick={()=>{setPage("landing")}} style={{cursor : "pointer"}}></i>
                </div> */}
          {/* <div className="progress-bar-wrapper" style={{backgroundColor: 'var(--accent-color)'}}>
                    <div className="progress bar">
                        <div style={{height: "0.5em",width: "100%", backgroundColor : 'var(--main-bg-color)', borderRadius : "0 0 0 0"}}></div>
                    </div>
                </div> */}

          <div className="result-card-stack">
            <div className="result-card">
              <div className="result-card-image">
                <img
                  src={outcomeDetails[finalScore2DArray[0][2]].illustration}
                  alt=""
                />
              </div>
              <div className="result-card-text">
                <h6 style={{ margin: "1em 0 0 0" }}>You are most suited to:</h6>
                <h4 className="result-heading">{finalScore2DArray[0][0]}</h4>
                <p>{outcomeDetails[finalScore2DArray[0][2]].excerpt}</p>
                <Dropdown
                  item={outcomeDetails[finalScore2DArray[0][2]]}
                ></Dropdown>
              </div>
            </div>

            <div className="result-card">
              <div className="result-card-image">
                <img
                  src={outcomeDetails[finalScore2DArray[1][2]].illustration}
                  alt=""
                />
              </div>
              <div className="result-card-text">
                <h6 style={{ margin: "1em 0 0 0" }}>
                  Your second choice would be:
                </h6>
                <h4 className="result-heading">{finalScore2DArray[1][0]}</h4>
                <p>{outcomeDetails[finalScore2DArray[1][2]].excerpt}</p>
                <Dropdown
                  item={outcomeDetails[finalScore2DArray[1][2]]}
                ></Dropdown>
              </div>
            </div>
          </div>

          <div className="button-belt">
            <button
              className="hollow"
              onClick={() => {
                setPage("workings");
              }}
            >
              How we worked this out
            </button>

            <button
              className="hollow"
              onClick={() => {
                setPage("landing");
              }}
            >
              <i class="fas fa-redo"></i> Try Again
            </button>
          </div>

          <div className="related-links">
            <h3>Related articles</h3>
            <a
              target="_blank"
              href="https://www.studymelbourne.vic.gov.au/living-here/accommodation/types-of-accommodation"
              rel="noreferrer"
            >
              Learn more about types of accommodation
            </a>
            <a
              target="_blank"
              href="https://www.studymelbourne.vic.gov.au/living-here/accommodation/choosing-accommodation-thats-right-for-you"
              rel="noreferrer"
            >
              Choosing accommodation that’s right for you
            </a>
            <a
              target="_blank"
              href="https://www.studymelbourne.vic.gov.au/living-here/accommodation/setting-up-moving-in"
              rel="noreferrer"
            >
              Setting up and moving in
            </a>
          </div>
        </div>

        <Footer />
      </>
    );
  }
};

export default WeightedQuiz;
